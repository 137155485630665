import React from 'react'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Accordion from '../components/Accordion'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import Method from '../components/Method'
import Slide from '../components/Slide'

function MethodTemplate({pageContext}) {
  const shortcodes = { Accordion, FactBox, Carousel, Slide }
  const { method } = pageContext


  return (
    <div className="BirthControlImplant">
      <Method
        imageNames={method.frontmatter.images}
        name={method.frontmatter.title}
        previousLink={method.frontmatter.previousLink}
        nextLink={method.frontmatter.nextLink}>

        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {method.body}
          </MDXRenderer>
        </MDXProvider>
      </Method>
    </div>
  )
}

export default MethodTemplate