import React from 'react'
import './Method.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'gatsby'
import SectionHeader from './SectionHeader';
import BreadCrumbs from './BreadCrumbs'
import Ankle from './Ankle'
import Footer from './Footer'
import "swiper/css/pagination"
// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper';

// install Swiper modules

SwiperCore.use([Pagination]);

const Method = ({imageNames, name, previousLink, nextLink, children}) => {

  const imageSlides = imageNames.map((name, index) => {
    return <SwiperSlide key={index}><img src={`/assets/${name}`} alt={name} /></SwiperSlide>
  })

  return (
    <div className='Method'>
      <SectionHeader >
      </SectionHeader>
      <Link class="back" to={'/birth-control/birth-control-basics'}>{'< back'}</Link>
      <Swiper
        centeredSlides={true}
        pagination={{
          "clickable": true}}
      >
        {imageSlides}
      </Swiper>
      <div className='navbar'>
        <div className='navbutton'><Link to={previousLink}>{'<'}</Link></div>
        <h2>{name}</h2>
        <div className='navbutton'><Link to={nextLink}>{'>'}</Link></div>
      </div>
      <div className="crib">
        {children}
      </div>
      <Ankle>
      </Ankle>
      <Footer>
      </Footer>
    </div>
  )
}

export default Method